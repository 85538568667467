<template>
    <div class="col-7 col-xs-12 col-md-7">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <h4>{{ $t('message.pendingTransfers') }}</h4>
                </v-col>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="refresh()"><v-icon>refresh</v-icon></v-btn>
                    <v-btn class="ml-3 info lighten-2 text--darken-1 black--text" @click="markAsProcessed()">{{ $t('message.markAsProcessed') }}</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.transfers"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="allPendingTmsTransfers"
            :items-per-page="20"
            :options.sync="tableOptions"
            :search="searchTerm"
            :sort-by="['AppicTmsTransfer.model','AppicTmsTransfer.transaction','AppicTmsTransfer.created']"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="transfersTable"
            item-key="AppicTmsTransfer.id"
            show-select
            multi-sort
            v-model="selectedTransfers"
        >
            <template v-slot:item.AppicTmsTransfer.model="{ item }">
                <span class="font-weight-bold text-no-wrap">{{ capitalize(item.AppicTmsTransfer.model) }}</span>
            </template>
            <template v-slot:item.AppicTmsTransfer.transaction="{ item }">
                <span class="text-no-wrap">{{ capitalize(item.AppicTmsTransfer.transaction) }}</span>
            </template>
            <template v-slot:item.AppicTmsTransfer.values="{ item }">
                <v-tooltip
                    open-on-click
                    right
                >
                    <template v-slot:activator="{ on, attrs }">
                        <span class="text-no-wrap truncate blue--text darken-1" v-bind="attrs" v-on="on">{{ item.AppicTmsTransfer.values }}</span>
                    </template>
                    <div style="width: 400px !important; overflow-y: auto; overflow-wrap: break-word">{{ item.AppicTmsTransfer.values }}</div>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {api} from "Api";
import {capitalize} from "Helpers/helpers";

export default {
    name: "PendingTmsTransfers",
    data() {
        return {
            allPendingTmsTransfers: [],
            loading: {
                filterResults: false,
                transfers: false
            },
            searchTerm: null,
            selectedTransfers: [],
            tableOptions: {
                page: 1
            },
        }
    },
    computed: {
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.recordId'),
                    value: 'AppicTmsTransfer.id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.model'),
                    value: 'AppicTmsTransfer.model',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.transaction'),
                    value: 'AppicTmsTransfer.transaction',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.timestamp'),
                    value: 'AppicTmsTransfer.created',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.appicId'),
                    value: 'AppicTmsTransfer.appic_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.initiator'),
                    value: 'AppicTmsTransfer.userid',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.values'),
                    value: 'AppicTmsTransfer.values',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        capitalize,
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        getAllPendingTmsTransfers() {
            this.loading.transfers = true
            api
                .get('/admin/appic-tms-transfers',{
                    params: {
                        conditions: [
                            {
                                field: 'appic_tms_transfers.processed',
                                value: null
                            }
                        ]
                    }
                })
                .then((response) => {
                    this.allPendingTmsTransfers = response.data.data
                    this.loading.transfers = false
                })
                .catch(() => {
                    this.loading.transfers = false
                })
        },
        async markAsProcessed() {
            if(this.selectedTransfers.length > 0) {
                if (await this.$root.$confirm(this.$t('message.markAsProcessed') + ' ', this.$t('message.confirmations.continueMarkAsProcessedAction'), {color: 'orange'})) {
                    let transfers = []
                    this.selectedTransfers.forEach(t => {
                        transfers.push(t.AppicTmsTransfer.id)
                    })
                    api
                        .put('/admin/appic-tms-transfers/mark-as-processed',{
                            transfers: transfers
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.$toast.success(this.$t('message.successes.transactionsMarkProcessed'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.getAllPendingTmsTransfers()
                            } else {
                                this.$toast.error(this.$t('message.errors.transactionsNotMarkProcessed'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.transactionsNotMarkProcessed'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.noTransactionsSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        refresh() {
            this.searchTerm = null
            this.getAllPendingTmsTransfers()
        }
    },
    created() {
        if(this.allPendingTmsTransfers.length == 0) this.getAllPendingTmsTransfers()
    },
    mounted () {
        this.$title =  this.$t('message.titles.pendingTmsTransfers')
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}

.v-data-table th:first-child {
    background-color: #c5e1a5 !important;
    border-color: #c5e1a5 !important;
}
.v-data-table td:first-child {
    padding-left: 3px !important;
}
.v-data-table .v-data-table-header th {
    white-space: nowrap;
}
</style>